import _ from 'lodash'

function createEntity(user, name, action, dispatch) {
    const authHeader = `Bearer ${user.token.trim()}`.trim()
    const url = user.apiUrl + `/${name}/`
    fetch(url, {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify(action.payload),
        headers: {
            "Authorization": authHeader,
            "Content-Type":"application/json"
        },
    }).then(resp => {
        if(resp.status === 200) {
            resp.json().then(data => {
                dispatch({
                    type: `${action.type}_SUCCESS`,
                    payload: data
                })
            })
        } else if(resp.status === 400) {
            resp.json().then(data => {
                dispatch({
                    type: `${action.type}_ERROR`,
                    payload: _.get(data, 'message', 'bad request error')
                })
            })
        } else {
            dispatch({
                type: `${action.type}_ERROR`,
                payload: `${resp.status}, ${resp.statusText}`
            })
        }
    }).catch(err => {
        console.log('fetch error: ', err)
        dispatch({
            type: `${action.type}_ERROR`,
            payload: 'fetch error'
        })
    })
}

export default createEntity