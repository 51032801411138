function createActionTypes(name) {
    var actionTypes = {}
    var actionTypesList = []

    function actionsTriplet(name) {
        return [
            `${name}`,
            `${name}_CONFIRMED`,
            `${name}_ERROR`,
        ]
    }

    const types = [
        `GET_${name}`,
        `GET_${name}_VERSIONS`,
        `CREATE_${name}`,
        `UPDATE_${name}`,
        `DELETE_${name}`,
        `GET_${name}_LIST`
    ]
    var actions = []

    types.forEach(type => {
        actions.push(actionsTriplet(type)[0])
        actionTypesList = actionTypesList.concat(actionsTriplet(type))
    })

    const localTypes = [
        `RESET_CREATE_${name}`
    ]

    actions = actions.concat(localTypes)

    actionTypesList.forEach(actionType => {
        actionTypes[actionType] = actionType
    })

    return {
        actions: actions,
        actionTypes: actionTypes,
        actionTypesList: actionTypesList,
        types: types,
        tripletGenerator: actionsTriplet
    }
}

export default createActionTypes
