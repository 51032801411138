import dayjs from 'dayjs'
import _ from 'lodash'

function createInitialState(name) {
    var initialState = {
        entityName: name,
        user: false,
        listStatus: false,
        listFetchError: false,
        list: false,
        dict: false,
        createNewStatus: false,
        createNewError: false,
        newlyCreated: false,
        recentlyDeleted: false
    }
    initialState[name] = {}
    initialState['list'] = []
    return initialState
}

function dictToList(dict) {
    return _.keys(dict).map(key => {
        return dict[key]
    })
}


function createReducer(name, actions) {
    const NAME = _.upperCase(name)
    const reducer = (state, action) => {
        /**
         * Create actions
         */
        if (action.type === `CREATE_${NAME}`) {
            return {
                ...state,
                createNewStatus: 'creating',

            }
        } else if (action.type === `CREATE_${NAME}_SUCCESS`) {
            // TODO: Insert new script in list and dict
            let entityDict = state.dict
            entityDict[action.payload._id] = action.payload
            return {
                ...state,
                createNewStatus: false,
                createNewError: false,
                newlyCreated: action.payload,
                dict: entityDict,
                list: dictToList(entityDict)
            }
        } else if (action.type === `CREATE_${NAME}_ERROR`) {
            return {
                ...state,
                createNewStatus: false,
                createNewError: action.payload,
                newlyCreated: false
            }
        }
        if(action.type === `RESET_CREATE_${NAME}`) {
            return {
                ...state,
                createNewStatus: false,
                createNewError: false,
                newlyCreated: false,
            }
        }

        /**
         * Update actions
         */
        if (action.type === `UPDATE_${NAME}`) {
            const entityId = action.payload._id
            let entityDict = state.dict
            entityDict[entityId] = action.payload
            entityDict[entityId].updating = true
            return {
                ...state,
                dict: entityDict,
                list: dictToList(entityDict)
            }
        } else if (action.type === `UPDATE_${NAME}_SUCCESS`) {
            let entityDict = state.dict
            // entityDict[action.payload._id] = action.payload
            entityDict[action.payload._id].updating = false
            entityDict[action.payload._id].updateSuccessful = true
            return {
                ...state,
                dict: entityDict,
                list: dictToList(entityDict)
            }
        } else if (action.type === `UPDATE_${NAME}_ERROR`) {
            let entityDict = state.dict
            let entityIdWithError = _.get(action, 'id', false)
            if(entityIdWithError) {
                entityDict[entityIdWithError].updating = false
                entityDict[entityIdWithError].updateError = action.payload
            }
            return {
                ...state,
                dict: entityDict,
                list: dictToList(entityDict)
            }
        }

        /**
         * Delete actions
         */
        if (action.type === `DELETE_${NAME}`) {
            const entityId = action.payload._id
            let entityDict = state.dict
            entityDict[entityId].deleting = true
            return {
                ...state,
                dict: entityDict,
                list: dictToList(entityDict)
            }
        } else if(action.type === `DELETE_${NAME}_SUCCESS`) {
            const entityId = action.payload.id
            let entityDict = state.dict
            let deletedEntity = entityDict[entityId]
            delete entityDict[entityId]
            return {
                ...state,
                dict: entityDict,
                list: dictToList(entityDict),
                recentlyDeleted: deletedEntity
            }
        } else if(action.type === `DELETE_${NAME}_ERROR`) {
            const entityId = action.id
            let entityDict = state.dict
            entityDict[entityId].deleting = false
            entityDict[entityId].deleteError = action.payload
            return {
                ...state,
                dict: entityDict,
                list: dictToList(entityDict),
                deleteError: action.payload
            }
        }
        /**
         * Version actions
         */
        if(action.type === `GET_${NAME}_VERSIONS`) {
            let entityDict = state.dict
            entityDict[action.payload._id].loadingVersions = true
            return {
                ...state,
                dict: entityDict,
                list: dictToList(entityDict)
            }
        } else if(action.type === `GET_${NAME}_VERSIONS_SUCCESS`) {
            let entityDict = state.dict
            entityDict[action.payload.id].loadingVersions = false
            entityDict[action.payload.id].loadedVersions = true
            entityDict[action.payload.id].versions = action.payload.versions
            return {
                ...state,
                dict: entityDict,
                list: dictToList(entityDict)
            }
        } else if(action.type === `GET_${NAME}_VERSIONS_ERROR`) {

        }

        /**
         * List actions
         */
        if(action.type.endsWith("_LIST")) {
            return {
                ...state,
                listStatus: 'fetching'
            }
        } else if(action.type.endsWith("_LIST_SUCCESS")) {
            return {
                ...state,
                listStatus: 'fetched',
                lastListFetch: dayjs(),
                listFetchError: false,
                list: action.payload,
                dict: _.keyBy(action.payload, '_id')
            }
        
        } else if(action.type.endsWith("_LIST_ERROR")) {
            return {
                ...state,
                listStatus: 'error',
                listFetchError: action.payload
            }
        } else {
            switch(action.type) {
                case "SET_USER":
                    return {
                        ...state,
                        user: action.payload
                    }
                default:
                    return state
            }
        }
        
        
        
    }
    return reducer
}

export {
    createInitialState,
    createReducer
}