import React, { useState, useContext } from 'react'
import {
    Input, Button, Form, Message, Dimmer, Loader
} from 'semantic-ui-react'
import useKeyPress from './useKeyPress'
import _ from 'lodash'

const NewEntityForm = ({
    context
}) => {
    const entityState = useContext(context)
    const name = entityState.entityName
    const [newName, setNewName] = useState("")

    const creating = entityState.createNewStatus ? true : false
    return (
        <div className='new-entity-form'>
            {entityState.newlyCreated ? (
                <React.Fragment>
                    <h3>Success!</h3>
                    <Message positive>new {name} has successfully been created.</Message>
                    
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <h3 style={{paddingLeft: 10}}>New {name}</h3>
                    <div className='ui form'>
                            {creating && (
                                <Dimmer active style={{borderRadius: 10}}>
                                <Loader>Creating</Loader>
                                </Dimmer>
                            )}
                        <div style={{padding: 10}}>
                        <Form.Field>
                            
                            <label>Name</label>
                            <Input
                                placeholder='name...'
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                                disabled={creating}
                                />
                        </Form.Field>
                        </div>
                    </div>
                    <div style={{padding: 10}}>
                        <Button onClick={() => {
                                entityState.actions[`create${_.capitalize(name)}`]({
                                    name: newName
                                })
                            }}
                            disabled={creating}>
                            Create new {name}
                        </Button>
                    </div>
                    {entityState.createNewError && (
                        <div style={{padding: 10}}>
                            <Message negative>
                                {entityState.createNewError}
                            </Message>
                        </div>
                    )}
                    
                </React.Fragment>
            )}
        </div>
    )
}

export default NewEntityForm