import _ from 'lodash'

function getVersions(user, name, action, dispatch) {
    const authHeader = `Bearer ${user.token.trim()}`.trim()
    const url = user.apiUrl + `/${name}/versions?id=${action.payload._id}`
    fetch(url, {
        headers: {
            "Authorization": authHeader,
        },
        credentials: "include"
    }).then(resp => {
        console.log(resp)
        if(resp.status === 200) {
            resp.json().then(data => {
                dispatch({
                    type: `${action.type}_SUCCESS`,
                    payload: {
                        id: action.payload._id,
                        versions: data
                    }
                })
            })
        } else if (resp.status === 400) {
            resp.json().then(error_message => {
                dispatch({
                    type: `${action.type}_ERROR`,
                    payload: error_message
                })
            })
        } else {
            dispatch({
                type: `${action.type}_ERROR`,
                payload: `${resp.status}, ${resp.statusText}`
            })
        }
    })
    .catch(err => {
        dispatch({
            type: `${action.type}_ERROR`,
            payload: 'fetch error'
        })
    })
}

export default getVersions