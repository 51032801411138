import _ from 'lodash'
import dayjs from 'dayjs'

function updateEntity(user, name, action, dispatch) {
    const authHeader = `Bearer ${user.token.trim()}`.trim()
    const url = user.apiUrl + `/${name}/`
    const updatedAt = dayjs(action.payload.updatedAt)
    const now = dayjs()
    const storeVersionThreshold = 30
    if(!_.get(action.payload, 'storeVersion', false)) {
        action.payload.storeVersion = now.diff(updatedAt, 'second') < storeVersionThreshold
    }
    fetch(url, {
        method: "PUT",
        body: JSON.stringify(action.payload),
        headers: {
            "Authorization": authHeader,
            "Content-Type":"application/json"
        },
        credentials: "include"
    }).then(resp => {
        if(resp.status === 200) {
            resp.json().then(data => {
                dispatch({
                    type: `${action.type}_SUCCESS`,
                    payload: data
                })
            })
        } else if(resp.status === 400) {
            resp.json().then(data => {
                dispatch({
                    type: `${action.type}_ERROR`,
                    payload: data,
                    id: action.payload._id
                })
            })
        } else {
            dispatch({
                type: `${action.type}_ERROR`,
                payload: `${resp.status}, ${resp.statusText}`,
                id: action.payload._id
            })
        }
    }).catch(err => {
        console.log('fetch error: ', err)
        dispatch({
            type: `${action.type}_ERROR`,
            payload: 'fetch error',
            id: action.payload._id
        })
    })
}

export default updateEntity