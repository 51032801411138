import _ from 'lodash'

function createActions(name, actionTypes) {
    this.actions = {}
    this.use = (dispatch) => {
        actionTypes.actions.forEach(type => {
            var actionName = _.camelCase(type)
            var directName = actionName.replace(_.capitalize(name), '')
            this.actions[actionName] = (payload) => {
                dispatch({
                    type: type,
                    payload: payload
                })
            }
            this.actions[directName] = (payload) => {
                dispatch({
                    type: type,
                    payload: payload
                })
            }

        })
        return this.actions
    }
}

export default createActions