import _ from 'lodash'

function deleteEntity(user, name, action, dispatch) {
    console.log("Delete entity called")
    const authHeader = `Bearer ${user.token.trim()}`.trim()
    const url = user.apiUrl + `/${name}/?id=${action.payload._id}`
    fetch(url, {
        method: "DELETE",
        headers: {
            "Authorization": authHeader,
        },
        credentials: "include"
    }).then(resp => {
        if(resp.status === 200) {
            resp.json().then(data => {
                console.log(data)
                dispatch({
                    type: `${action.type}_SUCCESS`,
                    payload: data
                })
            })
        } else if(resp.status === 400) {
            resp.json().then(data => {
                dispatch({
                    type: `${action.type}_ERROR`,
                    payload: data.message
                })
            })
        } else {
            dispatch({
                type: `${action.type}_ERROR`,
                payload: `${resp.status}, ${resp.statusText}`
            })
        }
    })
}

export default deleteEntity