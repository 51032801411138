import getList from './requests/getList'
import createEntity from './requests/createEntity'
import updateEntity from './requests/updateEntity'
import deleteEntity from './requests/deleteEntity'
import getVersions from './requests/getVersions'
import _ from 'lodash'

export const createMiddleware = (name, prefix, actionTypes) => {
    const NAME = _.upperCase(name)
    const applyMiddleware = (user, dispatch) => action => {
        if(actionTypes.types.includes(action.type)) {
            // List action
            if(action.type === `GET_${NAME}_LIST`) {
                getList(user, name, action ,dispatch)
            }

            // Get action
            if(action.type === `GET_${NAME}`) {
                console.log('implement get action')
            }

            // Create action
            if(action.type === `CREATE_${NAME}`) {
                createEntity(user, name, action, dispatch)
            }

            // Update action
            if(action.type === `UPDATE_${NAME}`) {
                updateEntity(user, name, action, dispatch)
            }

            // Delete action
            if(action.type === `DELETE_${NAME}`) {
                deleteEntity(user, name, action, dispatch)
            }

            if(action.type === `GET_${NAME}_VERSIONS`) {
                getVersions(user, name, action, dispatch)
            }
        }
        dispatch(action)
    }
    return applyMiddleware
}