import React, { useContext } from 'react'
import VersionsList from '../VersionsList'
import {
    Button
} from 'semantic-ui-react'
import _ from "lodash"

const EntitySummary = ({
    entity,
    context
}) => {
    const entityState = useContext(context)
    const versions = _.get(entity, 'versions', false)
    console.log(entityState)
    return (
        <div className='entity-summary'>
            <h2>{entity.name}</h2>
            <p></p>
            {versions ? (
                <VersionsList actions={entityState.actions} entity={entity} versions={versions} rollbackButton={true} />
            ) : (
                <div>
                    <Button
                        onPointerDown={() => {
                            entityState.actions.getVersions(entity)
                        }}
                        >Get versions</Button>
                </div>
            )}
        </div>
    )
}

export default EntitySummary