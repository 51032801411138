import React, { useContext, useState, useEffect } from 'react'
import {
    Input, Button, Form, Message
} from 'semantic-ui-react'
import { UserContext } from '../../userStore/store'
import _ from 'lodash'
import useKeyPress from './useKeyPress'

const LoginForm = ({
    init,
    showHeading
}) => {
    const user = useContext(UserContext)
    const [email, setEmail] = useState(init.email)
    const [password, setPassword] = useState(init.pw)
    const enterPress = useKeyPress('Enter')

    useEffect(() => {
        if(user.authenticated === false && user.authenticating === false && enterPress) {
            user.actions.userLogIn({email: email, password: password})
        }
    }, [enterPress, user])
    return (
        <div className='hp--login-form'>
            {user.authenticated ? (
               <React.Fragment>
               <h3>Signed in!</h3>
                <p>Sucessfully signed in with the following email address: {user.email}</p>
               </React.Fragment>
           ) : (
                <React.Fragment>
                {showHeading && (
                    <h3>Sign in</h3>
                )}
                {user.authenticating ? (
                    <p>Authenticating</p>
                ) : (
                    <div className='ui form'>
                        <Form.Field>
                            <label>email</label>
                        <Input placeholder='e-mail...' value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Form.Field>
                        <Form.Field>
                            <label>password</label>
                            <Input type='password' placeholder='password...' value={password} onChange={(e) => setPassword(e.target.value)} />
                        </Form.Field>
                        <Button onClick={() => user.actions.userLogIn({email: email, password: password})}>Sign in</Button>
                        {user.authenticationError ? (
                            <Message negative>
                            <p className='login-error'>{user.authenticationError}</p>
                            </Message>
                        ) : null}
                    </div>
                )}
                </React.Fragment>
           )}
        </div>
    )
}

LoginForm.defaultProps = {
    init: {email: "", pw: ""},
    showHeading: true
}

export default LoginForm