import React, { useState, useReducer, createContext, useEffect } from 'react'
import _ from 'lodash'
import createActionTypes from './actionTypes'
import { createInitialState, createReducer } from './reducer'
import createActions from './actions'
import { createMiddleware } from './middleware'

function createStore(name, settings) {
    const prefix = _.get(settings, 'prefix', '')
    const actionTypes = createActionTypes(_.upperCase(name))
    const initialState = createInitialState(name)
    const reducer = createReducer(name, actionTypes)

    const Context = createContext()
    
    const entityActions = new createActions(name, actionTypes)

    const applyMiddleware = createMiddleware(name, prefix, actionTypes)

    const Provider = ({ user, children }) => {
        const [state, dispatch] = useReducer(reducer, initialState)
        const [fetchedList, setFetchedList] = useState(false)
        const actions = entityActions.use(applyMiddleware(user, dispatch))
        const entity = {
            ...state,
            actions
        }
        useEffect(() => {
            if(state.user === false && user.token) {
                dispatch({
                    type: "SET_USER",
                    payload: user
                })
            }
        }, [state, dispatch, user])
        useEffect(() => {
            if(state.user && fetchedList === false) {
                actions[`get${_.capitalize(name)}List`]()
                setFetchedList(true)
            }
        })
        
        return (
            <Context.Provider value={entity}>
                {children}
            </Context.Provider>
        )
        
    }
    return {
        context: Context,
        provider: Provider
    }
}

export default createStore