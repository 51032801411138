import React, { useContext, useState } from 'react'
import {
    Input,
    Button,
    Form,
    Message
} from 'semantic-ui-react'
import {
    UserContext
} from '../../userStore/store'
import CreateAccountError from './CreateAccountError'
import './create-account-form.scss'

const CreateAccountForm = () => {
    const user = useContext(UserContext)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    var inputsDisabled = false
    if(user.authenticated || user.creatingAccount) {
        inputsDisabled = true
    }
    return (
        <div className='hp--create-account-form'>
            {user.authenticated ? (
                <React.Fragment>
                    <h3>Created account!</h3>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {user.creatingAccount ? (
                        <h3>Creating account</h3>
                    ) : (
                        <h3>Create account</h3>
                    )}
                </React.Fragment>
            )}
            <div className='ui form'>
                <Form.Field>
                    <label>email</label>
                    <Input
                        placeholder='e-mail...'
                        loading={user.creatingAccount}
                        disabled={inputsDisabled}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        />
                </Form.Field>
                <Form.Field>
                    <label>password</label>
                    <Input
                        placeholder='password...'
                        loading={user.creatingAccount}
                        disabled={inputsDisabled}
                        type='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        />
                </Form.Field>
                <Button
                    onClick={() => {
                        user.actions.createAccount({
                            email: email,
                            password: password
                        })
                    }}
                    disabled={inputsDisabled}
                    >
                        Create account
                </Button>
                <CreateAccountError
                    error={user.createAccountError}
                    />
            </div>
        </div>
    )
}

export default CreateAccountForm