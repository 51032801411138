import React, { useContext, useState } from 'react'
import _ from 'lodash'
import {
    Table,
    Loader,
    Message,
    Button,
    Modal,
    List
} from 'semantic-ui-react'
import EditEntityForm from '../EditEntityForm'
import DeleteEntityForm from '../DeleteEntityForm'
import EntitySummary from '../EntitySummary'
import dayjs from 'dayjs'
import './entity-list.css'

const EntityList = ({
    context,
    name
}) => {
    const entityState = useContext(context)
    const [modal, setModal] = useState(false)
    const [modalEntityId, setModalEntityId] = useState(false)

    const modalEnity = _.get(entityState.dict, modalEntityId, false)


    return (
        <div className='entity-list'>
            <Modal
                open={modal === 'edit-entity'}
                onClose={() => setModal(false)}
                size='mini'
                closeIcon
                >
                <Modal.Content>
                <h3>Edit entity: {modalEntityId}</h3>
                <EditEntityForm context={context} entity={modalEnity} />
                </Modal.Content>
            </Modal>
            <Modal
                open={modal === 'delete-entity'}
                onClose={() => setModal(false)}
                size='mini'
                closeIcon
                >
                <Modal.Content>
                    <h3>Delete {entityState.entityName}: {modalEntityId}</h3>
                    <DeleteEntityForm
                        cancelCallback={() => setModal(false)}
                        context={context}
                        entity={modalEnity} />
                </Modal.Content>
            </Modal>
            <Modal
                open={modal === 'entity-summary'}
                onClose={() => setModal(false)}
                closeIcon
                >
                <Modal.Content>
                    <EntitySummary
                        entity={modalEnity}
                        context={context}
                        />
                </Modal.Content>
            </Modal>
            {entityState.list ? (
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>id</Table.HeaderCell>
                            <Table.HeaderCell>{name} Name</Table.HeaderCell>
                            <Table.HeaderCell>Created at</Table.HeaderCell>
                            <Table.HeaderCell>Updated at</Table.HeaderCell>
                            <Table.HeaderCell>Versions</Table.HeaderCell>
                            <Table.HeaderCell>Controls</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {entityState.listFetchError ? (
                            <Table.Row>
                                <Table.Cell>
                                    <Message negative>
                                    {entityState.listFetchError}
                                    </Message>
                                </Table.Cell>
                            </Table.Row>
                        ) : (
                            <React.Fragment>
                                {entityState.list.map((entity) => {
                                    return (
                                        <Table.Row key={entity._id}>
                                            <Table.Cell
                                                className='entity-id-cell'
                                                onClick={() => {
                                                    setModal("entity-summary")
                                                    setModalEntityId(entity._id)
                                                }}
                                                >
                                                {entity._id}
                                            </Table.Cell>
                                            <Table.Cell>{entity.name}</Table.Cell>
                                            <Table.Cell>{dayjs(entity.createdAt).format('dddd, MMMM D, YYYY h:mm A')}</Table.Cell>
                                            <Table.Cell>{dayjs(entity.updatedAt).format('dddd, MMMM D, YYYY h:mm A')}</Table.Cell>
                                            <Table.Cell>{_.get(entity, 'versionCount', '-')}</Table.Cell>
                                            <Table.Cell>
                                                <List>
                                                    <List.Item>
                                                        <Button
                                                            onClick={() => {
                                                                setModal("edit-entity")
                                                                setModalEntityId(entity._id)
                                                            }}
                                                            size="tiny">
                                                            Edit
                                                        </Button>
                                                    </List.Item>
                                                    <List.Item>
                                                        <Button onClick={() => {
                                                                entityState.actions.getVersions(entity)
                                                            }}
                                                            size="tiny">
                                                            Get versions
                                                        </Button>
                                                    </List.Item>
                                                    <List.Item>
                                                        <Button
                                                            onClick={() => {
                                                                setModal("delete-entity")
                                                                setModalEntityId(entity._id)
                                                            }}
                                                            size="tiny">
                                                            Delete
                                                        </Button>
                                                    </List.Item>
                                                </List>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </React.Fragment>
                        )}
                        
                    </Table.Body>
                </Table>
            ) : (
                <Loader />
            )}
        </div>
    )
}

EntityList.defaultProps = {
    name: ''
}

export default EntityList