import _ from 'lodash'

function getList(user, name, action, dispatch) {
    fetch(user.apiUrl + `/${name}/list`, {
        headers: {
            'Authorization': `Bearer ${user.token}`
        },
        credentials: 'include'
    }).then(resp => {
        if(resp.status === 200) {
            resp.json().then(data => {
                dispatch({
                    type: `${action.type}_SUCCESS`,
                    payload: data
                })
            })
        } else {
            dispatch({
                type: `${action.type}_ERROR`,
                payload: `${resp.status}, ${resp.statusText}`
            })
        }
    }).catch(err => {
        console.log('fetch error: ', err)
        dispatch({
            type: `${action.type}_ERROR`,
            payload: 'fetch error'
        })
    })
}

export default getList